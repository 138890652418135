/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 9/16/2020
 * @Example
 */

import React from 'react';
import Config from "../../config";
import ReactLoading from 'react-loading';
import {Typography, withStyles} from "@material-ui/core";
import queryString from 'query-string'
import {connect} from "react-redux";
import * as homeActions from "../../redux/home/home_actions";
import {bindActionCreators, compose} from "redux";

const styles = {
    content: {
        textAlign: "center",
        padding: 15
    }
};
class AuthPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.loading !== this.props.loading && !this.props.loading) {
            this.checkAuth();
        }
    }

    checkAuth = () => {
        const query = queryString.parse(window.location.search);
        const {token} = query;
        const params = {
            token: token ? token : ""
        };
        Config.getToken = true;
        this.props.homeActions.getAuth(params, (error, data)=>{
            if (error){
                let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            if (data) {
                this.onLogin(data);
            }
        });
        // const data = {
        //     "user": {
        //         "UserID": "LEMONADMIN",
        //         "UserNameU": "Quản trị hệ thống",
        //         "UserDepartmentU": "Board of Directors",
        //         "UserDutyNameU": "Tổng giám đốc",
        //         "DateEntered": "2005-07-01T10:45:00.687Z",
        //         "Disabled": 0,
        //         "Email": "THINI.LE@DIGINET.COM.VN",
        //         "BirthDate": "1999-12-31T17:00:00.000Z",
        //         "UserAddress": "341 Điện Biên Phủ, Phường 15, Quận Bình Thạnh, Tp HCM",
        //         "Gender": 0,
        //         "IsSysManUser": 1,
        //         "TelNumber": "0367640083",
        //         "UserPictureURL": "https://apricot.diginet.com.vn/cdn-dev/file/C-Users-BICHTHUAN-Desktop-Maslow-dai-dien.jpg?path=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyZXN1bHQiOlt7InBhdGgiOiIvMjAxOS8wNS84ZmNhZjZkZWRhYTJmZmI2YWIwNzllY2UxZmY2Y2ZjNC5qcGciLCJvcmlnaW5hbEZpbGVOYW1lIjoiQy1Vc2Vycy1CSUNIVEhVQU4tRGVza3RvcC1NYXNsb3ctZGFpLWRpZW4uanBnIiwicHVybCI6Imh0dHA6Ly9hcHJpY290LmRpZ2luZXQuY29tLnZuOjYwNDAiLCJpdXJsIjoiaHR0cDovLzEwLjAuMC45OTo2MDQwIn1dLCJpYXQiOjE1NzE5OTc0MzUsImV4cCI6ODc5NzE5OTc0MzV9.AfnouZr-CoKeo14FfmV8153k-EcAdN1ri-71CSeRO_c"
        //     },
        //     "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjVmNjFhNmNkYWFjNTgxMmE2MjBkZThlZiIsImNyZWF0ZWRBdCI6IjIwMjAtMDktMTZUMDU6NDY6NTMuMDgyWiIsInVwZGF0ZWRBdCI6IjIwMjAtMDktMTZUMDU6NDY6NTMuMDgyWiIsImV4cGlyZWRBdCI6IjIwMjAtMDktMjZUMDU6NDY6NTMuMDgxWiIsInVzZXIiOnsiVXNlckFjY291bnQiOiJMRU1PTkFETUlOIiwiVXNlck5hbWUiOiJRdeG6o24gdHLhu4sgaOG7hyB0aOG7kW5nIn0sImNvbXBhbnkiOnsiRGl2aXNpb25JRCI6IkRJR0lORVQifSwiaWF0IjoxNjAwMjM1MjEzLCJleHAiOjE2MDI4MjcyMTN9.WidHuE14aba0K-KUU1CN-lj4LSBB9yuiDdTd0sRvll8",
        //     "openToken": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2VyQWNjb3VudCI6IkxFTU9OQURNSU4iLCJjb21wYW55IjoiIiwiaWF0IjoxNjAwMjM1MjEzLCJleHAiOjE2MjYxNTUyMTN9.neJ0JupcI9gjHCWG5SphhLP8OgLQdixwgo-v-YKJzbE"
        // };
        // this.onLogin(data);
    };

    onLogin = (data) => {
        let user = data.user || {};
        Config.base = user.UserBase;
        let lang = Config.getLocalStorage("langDHR");

        if (!lang || lang.length !== 2){
            lang = "vi";
            Config.setLocalStorage('langDHR', lang);
        }

        const expire = new Date().getTime() + 7*24*60*60*1000;

        Config.token = {
            id: data.token,
            expire: expire
        };
        user.type = 2;

        const menuType = 1;

        Config.setLocalStorage('MENUTYPEDHR', menuType);
        Config.setLocalStorage('PROFILEDHR', Config.encryptData(JSON.stringify(user)));
        Config.setLocalStorage('TOKENDHR', JSON.stringify(Config.token));

        Config.getMenuInfo(false, () => {
            this.redirectScreen();
        });
        //End get all menus

        //Get all modules
        Config.getModule();
        //End get all modules
    };

    redirectScreen = () => {
        const query = queryString.parse(window.location.search);
        const {voucher_id, route} = query;
        let pathname = route ? route : "/";
        pathname = pathname.indexOf("/") === 0 ? pathname.substring(1) : pathname;
        const pathinfo = {
            pathname: Config.getRootPath() + pathname,
            state: voucher_id ? {voucher_id: voucher_id} : null
        };
        Config.setLocalStorage('AUTHREDIRECTDHR', JSON.stringify(pathinfo));
        window.location.reload();
        // browserHistory.push({
        //     pathname: Config.getRootPath() + pathname,
        //     state: voucher_id ? {voucher_id: voucher_id} : null
        // });
    };

    render() {
        const {classes} = this.props;
        let label = "Đang chuyển tiếp, xin vui lòng chờ...";
        if (Config.language === "01") label = "Forward, please wait...";

        return (
            <div className="auth-page">
                <div className={"auth-page-content " + classes.content}>
                    <Typography variant={"h3"}>{label}</Typography>
                    <div className={"display_row align-center valign-middle"}>
                        <ReactLoading type={'bubbles'}
                                      width={'10%'}
                                      color={"#222B45"}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default compose(connect(null,
    (dispatch) => ({
        homeActions: bindActionCreators(homeActions, dispatch)
    })
), withStyles(styles))(AuthPage);
