/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 1/5/2021
 * @Example
 */

import React from 'react';
import Config from "../../config";
import {withStyles} from "@material-ui/core";
import {connect} from "react-redux";
import * as homeActions from "../../redux/home/home_actions";
import {bindActionCreators, compose} from "redux";
import { browserHistory } from "react-router";

const styles = {
    content: {
        textAlign: "center",
        padding: 15
    }
};
class MailLink extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    };

    componentDidMount () {
        const state = this.getInfo();
        const {Type, FormID, FormParentID} = state;
        if (!FormID) return false;
        const _FormID = FormParentID ? FormParentID : FormID;
        Config.setLocalStorage('MENUTYPEDHR', Number(Type ?? 0));
        Config.menuType = Number(Type ?? 0);
        browserHistory.push({
            pathname: Config.getRootPath() + _FormID,
            state: {...state, menuType: Number(Type ?? 0)}
        });
    }

    getInfo = (props) => {
        const { location } = props ? props : this.props;
        const params = new window.URLSearchParams(window.location.search);
        let state = {};
        for (let param of params.entries()) {
            state[param[0]] = param[1];
        }
        if (Object.keys(state).length > 0) {
            return state;
        } else if (location && location.state) {
            return location.state;
        } else {
            return null;
        }
    };

    render() {
        return null;
    }
}

export default compose(connect(null,
    (dispatch) => ({
        homeActions: bindActionCreators(homeActions, dispatch)
    })
), withStyles(styles))(MailLink);
