/**
 * @copyright 2019 @ DigiNet
 * @author tranghoang
 * @create 2019/08/09 10:37
 * @update 2017/08/12 10:37
 */

import React from 'react';
import {browserHistory} from "react-router";
import Config from "../../config";
import {Animated} from "react-animated-css";
import {Tooltip, withStyles,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    ExpansionPanel,
    Typography
} from "@material-ui/core";
import {Row} from "react-bootstrap";
import Icons from "../common/icons";
import IconMenu from "diginet-core-ui/icons/menu/v2";
import { Button, Badge } from "diginet-core-ui/components"
import Profile from "../header/profile";

const styles = theme => ({
    collapseBoardContent: {
        padding: '0 !important',
        flexDirection: 'column'
    },
    heading: {
        fontSize: 16,
        fontWeight: 450,
        textTransform: "uppercase",
    },
    headingMinium: {
        fontSize: 11,
        fontWeight: 450,
        textTransform: "uppercase",
        textAlign: "center",
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: 60
    },
    collapseBoard: {
        width: "100%",
        position: 'initial !important',
        boxShadow: 'initial !important',
        margin: '0 0 5px 0 !important'
    },
    ExpansionPanelSummary: {
        minHeight: 'initial !important',
        padding: "8px 0",
        width: "100%",
        color: theme.palette.primary.main,
        "& .MuiExpansionPanelSummary-content": {
            margin: "0 !important",
            display: "block"
        },
        "&.Mui-expanded": {
            minHeight: "inherit"
        }
    },
});
class SideNav extends React.Component{

    heightMenu = 42;

    constructor(props) {
        super(props);
        this.state={
            showMore: false,
            showMoreChild: false,
            expanded: false,
            expandGroup: {
                ModuleGroup: true,
            },
            selectedModule: null,
        };
    }

    componentDidMount() {
        const currentModule = Config.getLocalStorage('CURRENTMODULE', true);
        const moduleData = Config.module;
        if (!currentModule && moduleData?.length > 0) {
            Config.setLocalStorage('CURRENTMODULE', JSON.stringify(moduleData?.[0]));
            this.setState({ selectedModule: moduleData?.[0] });
        } else {
            this.setState({ selectedModule: currentModule });
        }
        this.toggleNav(this.props.expand);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const currentModule = Config.getLocalStorage('CURRENTMODULE');
        if (prevProps.expand !== this.props.expand) {
            this.toggleNav(this.props.expand);
        }
        if (!this.props.isModuleSidebar && currentModule && JSON.stringify(this.state.selectedModule) !== currentModule) {
            this.setState({ selectedModule: JSON.parse(currentModule) });
        }
    }

    getNestedChildren = (arr, parent) => {
        let out = [];
        arr.forEach((key)=>{
            if(key.ParentMenuID === parent) {
                let children = this.getNestedChildren(arr, key.MenuID);
                if(children.length) {
                    key.children = children;
                }
                out.push(key);
            }
        });
        return out;
    };

    toggleNav = (flag) => {
        const btnToogle = document.querySelector('#toogle-nav').children[0];
        if (typeof flag === "undefined") {
            if (this.refs['side-container'].clientWidth < 180) {
                // this.checkShowMore(this.dataMenuParse);
                this.refs['side-container'].classList.remove('minimum');
                if (btnToogle) {
                    btnToogle.classList.add('active');
                }
            } else {
                this.refs['side-container'].classList.add('minimum');
                if (btnToogle) {
                    btnToogle.classList.remove('active');
                }
            }
        } else {
            if (flag) {
                if (btnToogle) {
                    btnToogle.classList.add('active');
                }
                this.setState({expanded: true});
                this.refs['side-container'].classList.remove('minimum');
            } else {
                if (btnToogle) {
                    btnToogle.classList.remove('active');
                }
                this.setState({expanded: false});
                this.refs['side-container'].classList.add('minimum');
            }
        }
    };

    toggleMenuESS = (number) => {
        const {isTab, toggleMenuEss} = this.props;
        if(isTab === number) return;
        if(toggleMenuEss) toggleMenuEss(number, true);
    };

    handleChangeSelectedModule = (module) => {
        if (!module) return false;
        this.setState({ selectedModule: module }, () => {
            const { setCurrentModule } = this.props;
            setCurrentModule && setCurrentModule(module);
        });
    };

    render() {
        const {isTab, className, classes, isModule, isModuleSidebar, cusStyle, toggleModuleSidebar, badgeNumber} = this.props;
        const {expanded, expandGroup, selectedModule} = this.state;
        let dataMenu = null;
        if (isModule) {
            dataMenu = JSON.parse(JSON.stringify(Config?.module ?? null));
        } else if (Config.menu) {
            switch (isTab) {
                case 2:
                    dataMenu = [
                        {
                            AppFormID: "",
                            FormCall: "W39F3000",
                            FormDesc: Config.lang("Danh_sach_muc_tieu"),
                            FormID: "W39F3000",
                            FormIDPermission: "W39F3000",
                            IsAW: 0,
                            IsPermission: 0,
                            MenuGroupID: "Goal",
                            MenuID: "MHRP39N0008",
                            Parameters: "",
                            ProductID: "WHRP",
                            Type: "OKR",
                        },
                        {
                            AppFormID: "",
                            FormCall: "W39F3025",
                            FormDesc: Config.lang("Ban_do_muc_tieu"),
                            FormID: "W39F3025",
                            FormIDPermission: "",
                            IsAW: 0,
                            IsPermission: 0,
                            MenuGroupID: "Goal",
                            MenuID: "MHRP39N0009",
                            Parameters: "",
                            ProductID: "WHRP",
                            Type: "OKR",
                        },
                        {
                            AppFormID: "",
                            FormCall: "W39F3010",
                            FormDesc: Config.lang("Review_muc_tieu"),
                            FormID: "W39F3010",
                            FormIDPermission: "W39F3010",
                            IsAW: 0,
                            IsPermission: 1,
                            MenuGroupID: "Goal",
                            MenuID: "MHRP39N0012",
                            Parameters: "",
                            ProductID: "WHRP",
                            Type: "OKR",
                        },
                        {
                            AppFormID: "",
                            FormCall: "W39F3020",
                            FormDesc: Config.lang("Danh_sach_duyet_ket_qua_muc_tieu_ca_nhan"),
                            FormID: "W39F3020",
                            FormIDPermission: "W39F3020",
                            IsAW: 0,
                            IsPermission: 1,
                            MenuGroupID: "Goal",
                            MenuID: "MHRP39N0011",
                            Parameters: "",
                            ProductID: "WHRP",
                            Type: "OKR",
                        },
                        {
                            AppFormID: "",
                            FormCall: "W39F3035",
                            FormDesc: Config.lang("Xep_loai_danh_gia_nhan_vien"),
                            FormID: "W39F3035",
                            FormIDPermission: "W39F3035",
                            IsAW: 0,
                            IsPermission: 0,
                            MenuGroupID: "Goal",
                            MenuID: "MHRP39N0015",
                            Parameters: "",
                            ProductID: "WHRP",
                            Type: "OKR",
                        }
                    ];
                    break;
                case 1:
                    dataMenu = JSON.parse(JSON.stringify(Config.menu.listMSS ? Config.menu.listMSS : null));
                    break;
                case 0:
                default:
                    dataMenu = JSON.parse(JSON.stringify(Config.menu.listESS ? Config.menu.listESS : null));
                    break;
            }
            if (dataMenu && selectedModule) {
                if (selectedModule?.ModuleID !== 'ALL') {
                    dataMenu = dataMenu.map(menu => {
                        menu.MenuItem = menu?.MenuItem?.filter(item => item?.ModuleID?.includes(selectedModule.ModuleID));
                        return menu;
                    });
                }
                if(isTab !== 2) {
                    const moduleMenu = {
                        MenuGroupID: "Module",
                        MenuGroupName: Config.lang('Danh_sach_module'),
                        MenuItem: [{
                            FormCall: selectedModule?.ModuleID ?? '',
                            FormID: selectedModule?.ModuleID ?? '',
                            FormDesc: Config.language === '84' ? (selectedModule?.ModuleName ?? '') : (selectedModule?.ModuleName01 ?? ''),
                            MenuGroupID: "Module",
                            MenuID: selectedModule?.ModuleID ?? '',
                            ProductID: "WDHR",
                        }],
                        ShortName: Config.lang('Module'),
                        Type: "MODULE",
                    };
                    dataMenu.unshift(moduleMenu);
                }
            }
        }
        dataMenu = !dataMenu ? [] : dataMenu;
        // if (!isModuleSidebar) console.log(selectedModule, dataMenu)

        return(
            <div className={"side-container minimum " + className} ref={'side-container'} style={isModuleSidebar ? cusStyle : {}}>
                <div className="nav-scroll">
                    {!isModuleSidebar && <div className={'display_row align-center valign-middle group-menu-ess'}>
                        <div className="profile-view">
                            <Profile />
                        </div>
                        <div className={'display_row align-center switch-menu'}>
                            <Button
                                viewType={isTab === 0 ? 'filled' : 'text'}
                                color={"primary"}
                                label={"ESS"}
                                style={{width: 88}}
                                onClick={() => this.toggleMenuESS(0)}
                            />
                            <Button
                                viewType={isTab === 1 ? 'filled' : 'text'}
                                color={"primary"}
                                label={"MSS"}
                                style={{width: 88}}
                                onClick={() => this.toggleMenuESS(1)}
                            />
                        </div>
                    </div>}
                    {isModule ? (
                        <ul className={'nav-item pdl10 pdr10 item-ModuleGroup'}
                            key={'ul-0'}
                        >
                            <div className="display_row align-between align-center">
                                <ExpansionPanel
                                    expanded={expandGroup?.ModuleGroup ?? true}
                                    className={classes.collapseBoard}
                                    onChange={() => this.setState({ expandGroup: {...this.state.expandGroup, ModuleGroup: !expandGroup?.ModuleGroup}})}
                                >
                                    <ExpansionPanelSummary className={classes.ExpansionPanelSummary}>
                                        <Animated style={{width: '100%'}}
                                                animationIn="flipInX"
                                                animationInDuration={1000}
                                                isVisible={true}>
                                            <Tooltip title={expanded ? Config.lang('Danh_sach_module') : Config.lang('Module')}
                                                    classes={{tooltip: "no-margin"}}
                                                    disableHoverListener={expanded}
                                                    placement={expanded ? "bottom" : "right"}
                                                    aria-label={expanded ? Config.lang('Danh_sach_module') : Config.lang('Module')}>
                                                <div className={"display_row align-center align-between"}>
                                                    <Typography className={classes[!expanded ? "headingMinium" : "heading"]}>{expanded ? Config.lang('Danh_sach_module') : Config.lang('Module')}</Typography>
                                                    {expanded && <Icons className={"fa mgr5 display_row align-center " + (expandGroup?.ModuleGroup ? "fa-angle-down " : "fa-angle-left ") + classes.iconArrowCollapse}/>}
                                                </div>
                                            </Tooltip>
                                        </Animated>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails className={classes.collapseBoardContent}>
                                        <Row className={'dashboard-container--group ' + (dataMenu && dataMenu.length > 4 ? 'valign-top' : 'valign-middle')}>
                                            {dataMenu.map((item, idx)=>{
                                                return(
                                                    <ItemMenu key={idx} expanded={expanded} index={idx} data={item} toggleNav={this.toggleNav} isModule={isModule}
                                                        handleChangeSelectedModule={this.handleChangeSelectedModule}
                                                    />
                                                )
                                            })}
                                        </Row>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            </div>
                        </ul>
                    ) : isTab !== 2 && dataMenu && dataMenu.map((menu, idx) => {
                        // const cls = this.getClassName(o, idx);
                        if (menu.MenuItem && menu.MenuItem.length <= 0) return null;
                        const _expand = typeof expandGroup[menu.MenuGroupID] === "undefined" ? true : expandGroup[menu.MenuGroupID];
                        const name = !expanded ? menu.ShortName : menu.MenuGroupName;
                        return (
                            <ul className={'nav-item pdl10 pdr10 item-' + menu.MenuGroupID}
                                key={'ul-' + idx}
                            >
                                <div className="display_row align-between align-center">
                                    <ExpansionPanel
                                        key={menu.MenuGroupID}
                                        expanded={_expand}
                                        className={classes.collapseBoard}
                                        onChange={() => this.setState({ expandGroup: {...this.state.expandGroup, [menu.MenuGroupID]: !_expand}})}
                                    >
                                        <ExpansionPanelSummary className={classes.ExpansionPanelSummary}>
                                            <Animated style={{width: '100%'}}
                                                // key={o.FormID + idx}
                                                // ref={o.FormID}
                                                      animationIn="flipInX"
                                                      animationInDuration={1000}
                                                      isVisible={true}>
                                                <Tooltip title={menu.Type === 'MODULE' ? name : menu.MenuGroupName}
                                                         classes={{tooltip: "no-margin"}}
                                                         disableHoverListener={expanded}
                                                         placement={expanded ? "bottom" : "right"}
                                                         aria-label={menu.Type === 'MODULE' ? name : menu.MenuGroupName}>
                                                    <div className={"display_row align-center align-between"}>
                                                        <Typography className={classes[!expanded ? "headingMinium" : "heading"]}>{name}</Typography>
                                                        {expanded && <Icons className={"fa mgr5 display_row align-center " + (_expand ? "fa-angle-down " : "fa-angle-left ") + classes.iconArrowCollapse}/>}
                                                    </div>
                                                </Tooltip>
                                            </Animated>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails className={classes.collapseBoardContent}>
                                            <Row className={'dashboard-container--group ' + (dataMenu && dataMenu.length > 4 ? 'valign-top' : 'valign-middle')}>
                                                {menu.MenuItem && menu.MenuItem.map((item, idx)=>{
                                                    return(
                                                        <ItemMenu key={idx} expanded={expanded} index={idx} data={item} toggleNav={this.toggleNav}  toggleModuleSidebar={toggleModuleSidebar} />
                                                    )
                                                })}
                                            </Row>
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                </div>
                            </ul>
                        )
                    })}
                    {isTab === 2 && dataMenu && dataMenu.map((menu, idx) => {
                        return (
                            <ItemMenu key={idx} expanded={expanded} index={idx} data={menu} toggleNav={this.toggleNav} badgeNumber={badgeNumber}/>
                        )
                    })}
                </div>
                {/*<div className={'icon-nav'}>*/}
                {/*    <Image src={this.state.iconExpand} onClick={this.toggleNav}/>*/}
                {/*</div>*/}
            </div>
        )
    }
}

class ItemMenu extends React.Component {

    getClassName = (m, idx, child) => {
        const { isModule } = this.props;
        const pth = window.location.href.split('/');
        const mdl = (pth && pth.length > 0) ? pth[pth.length - 1] : '';
        const currentModule = Config.getLocalStorage('CURRENTMODULE', true, true);
        let cls = `${isModule ? 'module' : 'menu'}-${isModule ? m.ModuleID : m.MenuID}-${idx} menu-item-child`;
        if (child) cls += '-child';

        if(m.Disabled === true) {
            cls += ' disabled-icon';
        }

        if (m && ((mdl === m.FormCall && m.FormCall !== '') || (isModule && m.ModuleID === currentModule?.ModuleID))) {
            cls += ' active';
        }

        return cls;
    };

    onMenuClick = (item, idx) => {
        const { isModule, handleChangeSelectedModule, toggleModuleSidebar } = this.props;
        if (isModule) {
            if (!item) return false;
            Config.setLocalStorage('CURRENTMODULE', JSON.stringify(item));
            const selectModule = document.querySelector(`.module-${item?.ModuleID ?? ''}-${idx ?? ''}`);
            let el = document.querySelector('.menu-item-child.active');
            let elChild = document.querySelector('.menu-item-child-child.active');
            if (selectModule !== el && selectModule !== elChild) {
                if (el) el.classList.remove('active');
                if (elChild) elChild.classList.remove('active');
                if (selectModule) selectModule.classList.add('active');
            }
            handleChangeSelectedModule && handleChangeSelectedModule(item);
        } else {
            const menuGroupID = item.MenuGroupID;
            if (menuGroupID === 'Module') {
                toggleModuleSidebar && toggleModuleSidebar();
            } else {
                const url = item.FormCall;
                const parameter = item.Parameters ? JSON.parse(item.Parameters) : null;
                const params = {
                    menu: item,
                    ...(parameter ? { ...parameter } : {})
                };
                if (!url || url === '' || url === '#' || (item.children && item.children.length > 0)) {
                    if (document.body.offsetWidth <= 720) {
                        this.toggleNav();
                    }
                    return false;
                }
                if (url === '/') {
                    browserHistory.push({
                        pathname: Config.getRootPath() + '/',
                        state: params
                    });
                    let el = document.querySelector('.menu-item-child.active');
                    let elChild = document.querySelector('.menu-item-child-child.active');
                    if (el) el.className = 'menu-item-child';
                    if (elChild) elChild.className = 'menu-item-child-child';
                    const elHome = document.querySelector('.menu-item-child-home');
                    if (elHome) elHome.className = 'menu-item-child-home logo active';
                } else {
                    browserHistory.push({
                        pathname: Config.getRootPath() + url,
                        state: params
                    });
                    let el = document.querySelector('.menu-item-child.active');
                    let elChild = document.querySelector('.menu-item-child-child.active');
                    if (el) el.className = 'menu-item-child';
                    if (elChild) elChild.className = 'menu-item-child-child';
                    const elHome = document.querySelector('.menu-item-child-home');
                    if (elHome) elHome.className = 'menu-item-child-home logo';
                    if (this.refs[url] && this.refs[url].classList) this.refs[url].classList.add('active');
                }
            }
        }
        const {toggleNav} = this.props;
        if(toggleNav && document.body.offsetWidth <= 720){
            toggleNav();
        }
    };

    render(){
        const {data: o, index: idx, expanded, isModule, badgeNumber} = this.props;
        const cls = this.getClassName(o, idx);
        let height = this.heightMenu;
        const language = Config?.language ?? '84';
        const name = isModule ? (language === '84' ? o.ModuleName : o.ModuleName01) : (o.FormDesc || o.MenuName);

        const badgeOKR = badgeNumber?.OKR?.find(i => i.MenuID === o.MenuID) || 0;

        return(
            <ul
                id={o.MenuGroupID === 'Module' ? 'module-menu-side-nav' : ''}
                className={'nav-item item-' + (isModule ? o.ModuleID : o.MenuID)}
                style={{
                    height: height,
                    minHeight: height,
                    borderBottom: o.MenuGroupID === 'Module' ? `1px solid ${Config.coreTheme.colors?.line.system}` : 'none',
                    paddingBottom: o.MenuGroupID === 'Module' ? Config.coreTheme?.spacing(1.5) : 0,
                }}
                key={'ul-' + idx}
            >
                <div className="display_row align-between align-center">
                    <Animated style={{width: '100%'}}
                              animationIn="flipInX"
                              animationInDuration={1000}
                              isVisible={true}>
                        <Tooltip title={name}
                                 classes={{tooltip: "no-margin"}}
                                 placement={expanded ? "bottom" : "right"}
                                 aria-label={name}>
                            <div className={`${cls} ${o.MenuGroupID === 'Module' ? 'icon-module' : ''}`}
                                 key={(isModule ? o.ModuleID : o.MenuID) + idx}
                                 onClick={(e) => {
                                     this.onMenuClick(o, idx)
                                 }}>
                                    <Badge className={"menu-item-icon"} color={"danger"} content={badgeOKR?.NumWaitApproval} invisible={!badgeOKR?.NumWaitApproval}>
                                            <IconMenu type={'dhr'} name={isModule ? o.ModuleID : o.MenuID} width={22} height={22}/>
                                    </Badge>
                                <div className={"nav-item-name"}>
                                    {name}
                                </div>
                            </div>
                        </Tooltip>
                    </Animated>
                </div>
            </ul>
        )
    }
}

export default withStyles(styles, {withTheme: true})(SideNav);
