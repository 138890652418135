import {makeStyles} from "@material-ui/styles";

export const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        height: "100%",
        background: "darkgray",
        display: "flex",
        flexDirection: "column",
        padding: 15
    },
    wrapperDiv: {
        paddingLeft: theme.spacing(3)
    },
    mainTitle: {
      fontWeight: 800,
      letterSpacing: theme.spacing(0.3)
    },
    boxSub: {
      display: "flex",
      alignItems: "center"
    },
    subTitle: {
      fontSize: "1rem",
      fontWeight: "bold",
      fontStyle: "italic",
      padding: theme.spacing(1.2, 0),
    color: "rgba(107,107,107,0.85)",
    },
    titleContent: {
      borderLeft: "8px solid #FF0000",
      padding: theme.spacing(1),
      margin: theme.spacing(1, 0)
    },
    iconSubTitle: {
      color: "#0723bd8c",
      fontSize: "2.5rem",
      paddingRight: theme.spacing(1.5)
    },
    titleInput: {
        fontSize: "1rem",
        fontWeight: "bold",
        fontStyle: "italic",
        color: "#383838",
        marginRight: theme.spacing(1.5),
        padding: theme.spacing(1.2, 0)
    },
    paper: {
      margin: theme.spacing(2, 1),
      boxShadow: "0px 2px 12px 0px rgba(0,0,0,0.5)",
      borderRadius: 10,
      height: 80,
      alignItems: "center"
    },
    conven: {
      lineHeight: "30px",
      textAlign: "center",
      fontWeight: 800
    },
    radioButton: {
        padding: 0
    },
    clsfx: {
        clear: 'both'
    }
  }));