/**
 * @copyright 2019 @ DigiNet
 * @author tranghoang
 * @create 2019/08/12 10:37
 * @file src/container/app.js
 */

import React, { Component } from 'react';

import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import allReducers from '../../src/utils/allReducers';
import rootSaga from '../../src/utils/rootSaga';
import Index from './index';
import ThemeWrapper from "./themewrapper";
import Config from "../config";

/**Using Intl */
import viMessages from '../assets/globalize-devextreme/vi.json';
import enMessages from 'devextreme/localization/messages/en.json';
import { locale, loadMessages } from 'devextreme/localization';
import 'devextreme-intl';

/** localize CORE */

const sagaMiddleware = createSagaMiddleware();
const store = createStore(allReducers, applyMiddleware(...[sagaMiddleware, thunk]));

class App extends Component {
    constructor(props) {
        super(props);
        this.initMessages(); /**Using Intl */
        locale(this.getLocale()); /**Using Intl */

    }

    /**Using Intl */
    getLocale() {
        return Config.getLocalStorage('langDHR') ? Config.getLocalStorage('langDHR') : "vi"
    }

    /**Using Intl */
    initMessages() {
        loadMessages(viMessages);
        loadMessages(enMessages);
    }
    render() {
        const {children} = this.props;
        return (
            <Provider store={store}>
                <ThemeWrapper>
                    <Index>
                        {children}
                    </Index>
                </ThemeWrapper>
            </Provider>
        );
    }
}
sagaMiddleware.run(rootSaga);

export default App;
