/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 11/12/2019
 * @Example
 */

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Config from "../../config";
import { Column } from "devextreme-react/data-grid";
import * as _ from "lodash";
import GridContainer from "../grid-container/grid-container";
import { ModalHeader, ModalBody, Modal, Button, TextInput, ModalFooter } from "diginet-core-ui/components";

class PopupSelect2 extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: props && props.open,
            selectedRowKeys: props && props.selectedRowKeys ? props.selectedRowKeys : [],
        };
        this.filter = {
            limit: 10,
            skip: 0,
            StrSearch: "",
        };
        this.tmpSelectedRowKeys = props && props.selectedRowKeys ? props.selectedRowKeys : [];
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.open !== this.props.open) {
            this.setState({
                isOpen: this.props.open,
            });
        }
        if (JSON.stringify(prevProps.selectedRowKeys) !== JSON.stringify(this.props.selectedRowKeys)) {
            this.tmpSelectedRowKeys = this.props.selectedRowKeys;
        }
        if (JSON.stringify(prevProps.dataSource) !== JSON.stringify(this.props.dataSource)) {
            this.setState({ selectedRowKeys: this.tmpSelectedRowKeys });
        }
    }

    open = data => {
        const { multiple, onOpen } = this.props;
        if (data) {
            data = !Array.isArray(data) ? [data] : data;
            data = !multiple ? [data[0]] : data;
        }
        this.tmpSelectedRowKeys = [...data];
        this.setState(
            {
                isOpen: true,
                selectedRowKeys: [...data]
            },
            () => {
                if (onOpen) onOpen();
            }
        );
    };

    _onEntered = () => {
        setTimeout(() => {
            this.setState({
                selectedRowKeys: this.tmpSelectedRowKeys,
            });
        }, 300);
    };

    onClose = e => {
        const { onClose } = this.props;
        this.setState(
            {
                isOpen: false,
            },
            () => {
                if (onClose) onClose(e);
            }
        );
    };

    onSearch = () => {
        const { onSearch } = this.props;
        if (onSearch) onSearch({ strSearch: this.filter.StrSearch });
    };

    onChosen = () => {
        const { onChosen } = this.props;
        if (onChosen) onChosen(this.tmpSelectedRowKeys);
        this.onClose();
    };

    _selectionChanged = e => {
        const currentSelectedRowKeys = e.currentSelectedRowKeys;
        const currentDeselectedRowKeys = e.currentDeselectedRowKeys;
        const { dataSource } = this.props;
        if (currentDeselectedRowKeys.length === 1) {
            this.tmpSelectedRowKeys = this.tmpSelectedRowKeys.filter(e => {
                return currentDeselectedRowKeys.indexOf(e) < 0;
            });
        } else if (currentDeselectedRowKeys.length === dataSource.length) {
            this.tmpSelectedRowKeys = [];
        } else if (currentSelectedRowKeys.length > 0) {
            currentSelectedRowKeys.forEach(val => {
                if (this.tmpSelectedRowKeys.indexOf(val) < 0) {
                    this.tmpSelectedRowKeys.push(val);
                }
            });
        }
    };

    onSelectionChanged = e => {
        const { multiple } = this.props;
        if (!multiple) {
            if (e.currentSelectedRowKeys.length > 0) {
                this.tmpSelectedRowKeys = e.currentSelectedRowKeys;
                this.setState({
                    selectedRowKeys: e.currentSelectedRowKeys,
                });
            }
        } else {
            this._selectionChanged(e);
            this.setState({
                selectedRowKeys: e.selectedRowKeys,
            });
        }
    };

    onChangePage = page => {
        const { onLoad } = this.props;
        this.filter.skip = page * this.filter.limit;
        if (onLoad) onLoad(this.filter);
    };

    onChangePerPage = per => {
        const { onLoad } = this.props;
        this.filter.skip = 0;
        this.filter.limit = per;
        if (onLoad) onLoad(this.filter);
    };

    render() {
        const {
            dataSource,
            column,
            itemPerPage,
            skipPerPage,
            title,
            className,
            style,
            fullWidth,
            maxWidth,
            keyExpr,
            allowSearch,
            searchPlaceholder,
            listPerPage,
            totalItems,
            typePaging,
            multiple,
            gridProps,
        } = this.props;
        const { isOpen, selectedRowKeys } = this.state;
        const _itemPerPage = itemPerPage || itemPerPage === 0 ? itemPerPage : this.filter.limit;
        const _skipPerPage = skipPerPage || skipPerPage === 0 ? skipPerPage : this.filter.skip;

        return (
            <React.Fragment>
                <Modal
                    open={isOpen}
                    maxWidth={maxWidth}
                    fullWidth={fullWidth}
                    className={className}
                    style={style}
                    onClose={this.onClose}
                >
                    <ModalHeader title={title || Config.lang("DHR_Chon")}/>
                    <ModalBody style={{ height: "100%" }}>
                        {allowSearch && (
                            <TextInput
                                label={Config.lang("DHR_Tim_kiem")}
                                placeholder={searchPlaceholder || Config.lang("DHR_Nhap_de_tim_kiem")}
                                viewType={"outlined"}
                                onKeyDown={e => {
                                    if (e && e.keyCode === 13) {
                                        this.onSearch();
                                    }
                                }}
                                startIcon={"search"}
                                onChange={e => (this.filter.StrSearch = e.target.value)}
                            />
                        )}
                        <GridContainer
                            totalItems={totalItems}
                            itemPerPage={_itemPerPage}
                            skipPerPage={_skipPerPage}
                            dataSource={dataSource}
                            // loading={modalLoaded}
                            keyExpr={keyExpr}
                            typePaging={typePaging}
                            gridProps={{
                                style: { maxHeight: 410 },
                            }}
                            pagerFullScreen={false}
                            showBorders={false}
                            columnAutoWidth={true}
                            typeShort={window.innerWidth < 768}
                            height={"calc(100vh - 180px)"}
                            selection={{
                                allowSelectAll: !!multiple,
                                mode: "multiple",
                                selectAllMode: multiple ? "allPages" : "",
                                showCheckBoxesMode: "always",
                            }}
                            filterRow={{
                                visible: true,
                            }}
                            allowColumnResizing={true}
                            listPerPage={listPerPage}
                            selectedRowKey={selectedRowKeys}
                            onChangePage={this.onChangePage}
                            onChangePerPage={this.onChangePerPage}
                            onSelectionChanged={this.onSelectionChanged}
                            {...gridProps}
                        >
                            {column.map((e, idx) => {
                                return (
                                    <Column
                                        key={idx}
                                        caption={e.caption}
                                        dataField={e.dataField}
                                        visible={typeof e.visible === "undefined" ? true : e.visible}
                                        width={200}
                                    />
                                );
                            })}
                </GridContainer>
                    </ModalBody>
                    <ModalFooter style={{ borderTop: "1px solid #ddd" }}>
                        <Button
                            label={Config.lang("Luu")}
                            startIcon={"save"}
                            viewType={"filled"}
                            color={"info"}
                            disabled={_.isEmpty(selectedRowKeys)}
                            onClick={this.onChosen}
                        />
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

PopupSelect2.defaultProps = {
    allowSearch: true,
    typePaging: "remote",
};

PopupSelect2.propTypes = {
    dataSource: PropTypes.any,
    column: PropTypes.any,
    keyExpr: PropTypes.string,
    open: PropTypes.bool,
    value: PropTypes.any,
    itemPerPage: PropTypes.number,
    multiple: PropTypes.bool, //'single', 'multiple'
    className: PropTypes.string,
    style: PropTypes.string,
    title: PropTypes.string,
    fullWidth: PropTypes.bool,
    maxWidth: PropTypes.string,
    allowSearch: PropTypes.bool,
    searchPlaceholder: PropTypes.string,
    typePaging: PropTypes.string,

    onSearch: PropTypes.func,
    onLoad: PropTypes.func,
    onClose: PropTypes.func,
    onChosen: PropTypes.func,
};

export default PopupSelect2;
