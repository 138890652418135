/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 11/12/2019
 * @Example
 */

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Config from "../../config";
import { Column } from "devextreme-react/data-grid";
import GridContainer from "../grid-container/grid-container";

import { ModalHeader, ModalFooter, ModalBody, Modal, Button } from "diginet-core-ui/components";

class PopupSelect extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            selectedRowKeys: [],
        };
        this.tmpSelectRowKeys = [];
    }

    _onInit = () => {
        const { onInit } = this.props;
        const { isOpen } = this.state;
        if (isOpen) {
            if (onInit) onInit();
        }
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.open !== this.props.open) {
            this.setState({
                isOpen: this.props.open,
            });
        }
        if (JSON.stringify(prevProps.value) !== JSON.stringify(this.props.value)) {
            this.tmpSelectRowKeys = this.props.value;
        }
        this._onInit();
    }

    open = data => {
        const { mode, onOpen } = this.props;
        if (data) {
            data = !Array.isArray(data) ? [data] : data;
            data = mode === "single" ? [data[0]] : data;
        }
        this.tmpSelectRowKeys = data;
        this.setState(
            {
                isOpen: true,
            },
            () => {
                if (onOpen) onOpen();
            }
        );
    };

    _onEntered = () => {
        setTimeout(() => {
            this.setState({
                selectedRowKeys: this.tmpSelectRowKeys,
            });
        }, 300);
    };

    close = e => {
        const { onClose } = this.props;
        this.setState(
            {
                isOpen: false,
            },
            () => {
                if (onClose) onClose(e);
            }
        );
    };

    onSave = () => {
        const { onSave } = this.props;
        const { selectedRowKeys } = this.state;
        if (onSave) onSave(selectedRowKeys);
        this.close();
    };

    onSelectionChanged = e => {
        const { mode } = this.props;
        this.setState({
            selectedRowKeys: mode === "single" ? e.currentSelectedRowKeys : e.selectedRowKeys,
        });
    };

    _mapDataSource = (dataSource, selectedRowKeys) => {
        const { keyExpr, allowFilterSelected } = this.props;
        return dataSource.map(d => {
            d.sortOrder = allowFilterSelected && selectedRowKeys.includes(d[keyExpr]) ? 1 : 0;
            return d;
        });
    };

    render() {
        let {
            dataSource,
            column,
            itemPerPage,
            skipPerPage,
            listPerPage,
            typePaging,
            title,
            maxWidth,
            mode,
            keyExpr,
            ...props
        } = this.props;
        const { isOpen, selectedRowKeys } = this.state;

        dataSource = this._mapDataSource(dataSource, selectedRowKeys);

        return (
            <React.Fragment>
                <Modal {...props} open={isOpen} maxWidth={maxWidth} onEntered={this._onEntered} onClose={this.close}>
                    <ModalHeader title={title || Config.lang("Chon")} />
                    <ModalBody>
                        <GridContainer
                            itemPerPage={itemPerPage}
                            skipPerPage={skipPerPage}
                            listPerPage={listPerPage}
                            height={() => {
                                return window.innerHeight - 240;
                            }}
                            pagerFullScreen={false}
                            repaintChangesOnly={true}
                            allowColumnReordering={true}
                            highlightChanges={true}
                            keyExpr={keyExpr}
                            dataSource={dataSource}
                            columnAutoWidth={true}
                            rowAlternationEnabled={true}
                            filterRow={{
                                visible: true,
                            }}
                            selectedRowKey={selectedRowKeys}
                            selection={{
                                allowSelectAll: mode === "multiple",
                                mode: "multiple",
                                selectAllMode: "allPages",
                                showCheckBoxesMode: "always",
                            }}
                            onSelectionChanged={this.onSelectionChanged}
                            typePaging={typePaging}
                            showRowLines={true}
                            showBorders={false}
                            showColumnLines={false}
                            noDataText={Config.lang("No_data")}
                        >
                            {column.map(e => {
                                return <Column {...e} key={e.dataField} caption={e.caption} dataField={e.dataField} />;
                            })}
                            <Column dataField={"sortOrder"} visible={false} sortOrder={"desc"} />
                        </GridContainer>
                    </ModalBody>
                    <ModalFooter style={{ borderTop: "1px solid #ddd" }}>
                        <Button
                            label={Config.lang("Luu")}
                            startIcon={"save"}
                            viewType={"filled"}
                            color={"info"}
                            onClick={this.onSave}
                        />
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

PopupSelect.defaultProps = {
    itemPerPage: 30,
    skipPerPage: 0,
    listPerPage: [30, 60, 100],
    typePaging: "remote",
    maxWidth: "md",
};

PopupSelect.propTypes = {
    dataSource: PropTypes.any,
    column: PropTypes.any,
    keyExpr: PropTypes.string,
    open: PropTypes.bool,
    value: PropTypes.any,
    itemPerPage: PropTypes.number,
    mode: PropTypes.string, //'single', 'multiple'
    className: PropTypes.string,
    style: PropTypes.string,
    title: PropTypes.string,
    fullWidth: PropTypes.bool,
    maxWidth: PropTypes.string,
    allowFilterSelected: PropTypes.bool,
    listPerPage: PropTypes.array,
    skipPerPage: PropTypes.number,
    /**
     * @typePaging: String
     * "default": With default type of DataGrid Devextreme
     * "normal": With type of HR customize paging (local).
     * "remote": With type of HR customize paging (remote). Require props:
     *      - @totalItems: Total of all data.
     */
    typePaging: PropTypes.oneOf(["default", "normal", "remote"]),

    onClose: PropTypes.func,
    onSave: PropTypes.func,
};

export default PopupSelect;
