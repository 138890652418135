/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 6/24/2022
 * @Example
 */

import React, {useEffect, useMemo, useRef, useState}                from "react";
import {useDispatch}                                                from "react-redux";
import {Modal, ModalBody, ModalHeader, TextInput, Row, Col, Button} from 'diginet-core-ui/components';
import _                                                            from "lodash";
import PropTypes                                                    from 'prop-types';
import Config                                                       from '../../config';
import GridContainer                                                from "../grid-container/grid-container";
import ModalFooter                                                  from "diginet-core-ui/components/modal/footer";

const initData    = {
    rows:  [],
    total: 0
};
const ChooseModal = React.memo((props) => {
    //init..
    const dispatch = useDispatch();

    //state variables..
    const {
              open, title, mode, dataFilter = {}, dataSource: _dataSource, keyExpr, actionLoadGrid, typePaging,
              selected, width, zIndex, ModalProps, GridProps,
              children, columns, onChangePage, onChangePerPage, onLoad, onClose, onChoosed
          }                                     = props;
    const [dataGrid, setDataGrid]               = useState(initData);
    const [dataGridLoading, setDataGridLoading] = useState(false);

    //refs variables..
    const filter             = useRef({
        skip:   0,
        limit:  10,
        search: "",
        ...dataFilter
    });
    const refGrid            = useRef(null);
    const refSelectedRowKeys = useRef([]);
    const refSelectedRowData = useRef([]);
    const timerSearch        = useRef(null);
    const timerContentReady  = useRef(null);
    const selectAll  = useRef(false);

    const isRemotePaging     = useMemo(() => typePaging === "remote", [typePaging]);
    //init functions..
    const _createEventParams = (obj = {}) => {
        return {cancel: false, ...obj};
    };

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        filter.current = {
            ...filter.current,
            ...dataFilter
        };
    }, [dataFilter]);

    useEffect(() => {
        if (open) {
            if (selected) {
                if (_.isEmpty(selected)) {
                    refSelectedRowKeys.current = [];
                    refSelectedRowData.current = [];
                } else {
                    const _data = _dataSource || dataGrid;
                    if (typeof selected[0] === "string") {
                        refSelectedRowKeys.current = selected;
                        refSelectedRowData.current = _data.filter(d => refSelectedRowKeys.current.includes(d[keyExpr]));
                    } else {
                        if (keyExpr) {
                            refSelectedRowKeys.current = selected.map(s => s[keyExpr]);
                            refSelectedRowData.current = _data.filter(d => refSelectedRowKeys.current.includes(d[keyExpr]));
                        } else {
                            refSelectedRowKeys.current = selected;
                            refSelectedRowData.current = selected;
                        }
                    }
                }
            }
            if (!_dataSource) {
                loadData("init");
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, selected]);

    const loadData = (mode) => {
        if (!actionLoadGrid) return false;
        const params = {...filter.current, ...(dataFilter ? dataFilter : {})};

        let event = _createEventParams({mode, ...params});
        if (onLoad) onLoad(event);
        if (event.cancel || _dataSource) return false;

        setDataGridLoading(true);
        dispatch(actionLoadGrid(params, (error, data) => {
            setDataGridLoading(false);
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            } else if (data) {
                setDataGrid({
                    rows:  _.get(data, "rows", data || []),
                    total: _.get(data, "total", data.length || 0)
                });
            }
        }));
    };

    const _onChangePage = (page) => {
        filter.current.skip = page * filter.current.limit;
        let event           = _createEventParams({...filter.current});
        onChangePage && onChangePage(event);
        if (isRemotePaging && !event.cancel) loadData("changePage");
    };

    const _onChangePerPage = (perPage) => {
        filter.current.skip  = 0;
        filter.current.limit = perPage;
        let event            = _createEventParams({...filter.current});
        onChangePerPage && onChangePerPage(event);
        if (isRemotePaging && !event.cancel) loadData("changePerPage");
    };

    const _onChoosed = async () => {
        let event = _createEventParams({
            selectedRowKeys:  refSelectedRowKeys.current,
            selectedRowsData: refSelectedRowData.current
        });
        if (onChoosed) await onChoosed(event);
        if (!event.cancel) onClose("choose");
    };

    const _onSelectionChanged = (e) => {
        if (!e) return null;
        const {currentSelectedRowKeys, currentDeselectedRowKeys, selectedRowKeys, selectedRowsData} = e || {};
        if (mode === "single"
            && currentSelectedRowKeys.length > 0
            && selectedRowKeys.length !== currentSelectedRowKeys.length
        ) {
            e.component.selectRows(currentSelectedRowKeys);
        }

        const total = e.component.totalCount();
        if ((selectedRowKeys.length === total || selectedRowKeys.length === 0) && selectAll.current) {
            if (selectedRowKeys.length === total) e.component.deselectAll();
            if (selectedRowKeys.length === 0) selectAll.current = false;
        }
        selectAll.current = selectedRowKeys.length && (selectedRowKeys.length + currentDeselectedRowKeys.length) === total;

        if (currentSelectedRowKeys && currentSelectedRowKeys.length > 0) {
            let deselectKeys = [];
            currentSelectedRowKeys.map(item => {
                if (!refSelectedRowKeys.current.includes(item)) {
                    e.component.byKey(item).done(d => {
                        if (d.allowSelect === false) {
                            deselectKeys.push(item);
                        } else {
                            refSelectedRowKeys.current.push(item);
                            const rowData = selectedRowsData.find(d => d[keyExpr] === item);
                            if (rowData) refSelectedRowData.current.push(rowData);
                        }
                    });
                }
                return item;
            });
            if (deselectKeys.length) e.component.deselectRows(deselectKeys);
        }
        if (currentDeselectedRowKeys && currentDeselectedRowKeys.length > 0) {
            currentDeselectedRowKeys.map(item => {
                if (e.component.byKey(item)) {
                    e.component.byKey(item).done(d => {
                        if (d.allowSelect !== false) {
                            refSelectedRowKeys.current = refSelectedRowKeys.current.filter(f => {
                                return item !== f;
                            });
                            refSelectedRowData.current = refSelectedRowData.current.filter(d => {
                                return item !== d[keyExpr];
                            });
                        }
                    });
                }
                return item;
            });
            e.component.selectRows(refSelectedRowKeys.current);
        }
    };


    const renderDataGrid = useMemo(() => {
        let _data = _dataSource || dataGrid;
        _data     = {
            rows:  _data?.rows || _data || [],
            total: _data?.total || _data.length || 0
        };
        return <GridContainer
            reference={ref => refGrid.current = ref}
            itemPerPage={filter.current.limit}
            skipPerPage={filter.current.skip}
            keyExpr={keyExpr}
            pagerFullScreen={false}
            columnAutoWidth={true}
            allowColumnResizing={true}
            loading={dataGridLoading}
            typeShort={window.innerWidth < 768}
            typePaging={typePaging}
            height={window.innerHeight - 270 > 650 ? 650 : window.innerHeight - 270}
            onChangePage={_onChangePage}
            onChangePerPage={_onChangePerPage}
            totalItems={_.get(_data, "total", 0)}
            dataSource={_.get(_data, "rows", [])}
            filterRow={{
                visible:              typePaging !== "remote",
                showOperationChooser: false,
            }}
            selection={{
                allowSelectAll:     mode === "multiple",
                mode:               "multiple",
                selectAllMode:      "",
                showCheckBoxesMode: "always"
            }}
            onContentReady={e => {
                if (timerContentReady.current) clearTimeout(timerContentReady.current);
                timerContentReady.current = setTimeout(() => {
                    e.component.selectRows(refSelectedRowKeys.current);
                }, 300);
            }}
            onSelectionChanged={_onSelectionChanged}
            onEditorPreparing={e => {
                if (e.parentType === "dataRow" && e.type === "selection" && e?.row?.data?.allowSelect === false) {
                    e.editorOptions.disabled = true;
                }
            }}
            gridProps={{
                ...(!children ? {columns: columns} : {}),
                ...GridProps
            }}
        >
            {children}
        </GridContainer>
        // eslint-disable-next-line
    }, [_dataSource, dataGrid, mode, refSelectedRowKeys.current, dataGridLoading]);

    return (
        <Modal
            zIndex={zIndex}
            width={width}
            open={open}
            onClose={() => onClose && onClose("close")}
            {...ModalProps}
        >
            <ModalHeader>{title || Config.lang("Chon")}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12}>
                        {typePaging === "remote" && <TextInput
                            viewType={"outlined"}
                            endIcon={"Search"}
                            placeholder={Config.lang("Tim_kiem")}
                            onChange={(e) => {
                                const value = e?.target?.value || "";
                                if (timerSearch.current) clearTimeout(timerSearch.current);
                                timerSearch.current = setTimeout(() => {
                                    filter.current.search = value;
                                    filter.current.skip   = 0;
                                    loadData("search");
                                }, 700);
                            }}
                            value={filter.current.search}
                        />}
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12}>
                        {renderDataGrid}
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter className={"display_row"}>
                <Button
                    label={Config.lang("Chon")}
                    viewType="filled"
                    color="info"
                    startIcon="save"
                    onClick={_onChoosed}
                />
            </ModalFooter>
        </Modal>
    );
});


ChooseModal.defaultProps = {
    open:       false,
    typePaging: "normal",
    mode:       "multiple",
    width:      720,
    zIndex:     1020
};

ChooseModal.propTypes = {
    open:           PropTypes.bool,
    title:          PropTypes.string,
    mode:           PropTypes.string,
    width:          PropTypes.any,
    zIndex:         PropTypes.number,
    FormID:         PropTypes.string,
    typePaging:     PropTypes.string,
    keyExpr:        PropTypes.string.isRequired,
    dataFilter:     PropTypes.object,
    dataSource:     PropTypes.any,
    selected:       PropTypes.array,
    columns:        PropTypes.array,
    actionLoadGrid: PropTypes.any,

    ModalProps: PropTypes.object,
    GridProps:  PropTypes.object,

    onLoad:          PropTypes.func,
    onChangePage:    PropTypes.func,
    onChangePerPage: PropTypes.func,
    onClose:         PropTypes.func,
    onChoosed:       PropTypes.func
};

export default ChooseModal;

