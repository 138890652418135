/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 12/10/2019
 * @Example
 * @description include all general function by me.
 */

import React from "react";
import * as mainActions from "../redux/main/main_actions";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import Config from "../config";
import * as generalActions from "../redux/general/general_actions";
import * as homeActions from "../redux/home/home_actions";

class Controller extends React.Component {

    getGeneralActions = () => {
        return this.props.generalActions;
    };

    getMenuV2 = (cb) => {
        this.props.homeActions.getMenuV2(cb);
    };

    getFormInfo = (params, cb) => {
        this.props.homeActions.getFormInfo(params, cb);
    };

    getModule = (params, cb) => {
        this.props.homeActions.getModule(params, cb);
    };

    saveHistory = async (params, cb) => {
        return await this.props.generalActions.saveHistory(params, cb);
    };

    saveHistory2 = async (params, cb) => {
        return await this.props.generalActions.saveHistory2(params, cb);
    };

    /**
     * Set for general filter on header
     * @param filter
     */
    setFilter = (filter) => {
        this.props.mainActions.updateFilters(filter);
    };

    /**
     * Delete token device
     * @param cb
     */
    deleteDevice = (cb) => {
        if (Config.deviceToken) {
            this.props.mainActions.delDevice({tokenDevice: Config.deviceToken}, () => {
                if (cb) cb();
            });
        } else {
            if (cb) cb();
        }
    };

    render() { return null; }
}

export default connect(null,
    (dispatch) => ({
        generalActions: bindActionCreators(generalActions, dispatch),
        mainActions: bindActionCreators(mainActions, dispatch),
        homeActions: bindActionCreators(homeActions, dispatch)
    }), null, {forwardRef: true}
)(Controller);
