/*
 * @Author: thienpham
 * @Date: 2019-11-15 17:16:37
 * @Last Modified by: thienpham
 * @Last Modified time: 2019-11-15 17:21:47
 */
import React from "react";

import {Container} from "@material-ui/core";

import {useStyles} from "./styles";

import HeaderReport from "./report-header";
import FormReport from "./report-form";

const Report = (props) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Container style={{background: "#fff"}} maxWidth="md">
                <HeaderReport/>
                <FormReport {...props}/>
            </Container>
        </div>
    );
};

export default Report;
