/**
 * @copyright 2019 @ DigiNet
 * @author tranghoang
 * @create 2019/08/09 22:59
 * @update 2019/08/09 22:59
 * @file src/components/common/items/staff-item.js
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Config from "../../config";

class SearchToolBar extends Component {
    constructor(props) {
        super(props);
        this.timer = null;
        this.timeDeplay = 3000;
    }

    onSearch() {
        if (this.props.onSearch) this.props.onSearch(this.vlFilter);
    }

    onChange(e) {
        this.vlFilter = e ? e.target.value : null;

        if (this.timer) {
            clearTimeout(this.timer);
        }

        this.timer = setTimeout(() => {
            // console.log('============= this.vlFilter: ', this.vlFilter);
            if ((this.vlFilter === null || this.vlFilter.length === 0) && this.props.onReset) {
                this.props.onReset();
            } else {
                this.onSearch();
            }
        }, this.timeDeplay / 3);
    }


    render() {
        const {allowSearch, valueSearch} = this.props;

        return (
            <div className={allowSearch ? allowSearch : ''} style={{
                display: 'flex',
                borderRadius: 5,
                border: '1px solid rgb(206, 212, 218)',
                height: 28
            }}>

                <input type="text" style={{
                    border: 'none',
                    width: '100%',
                    outline: 'none',
                    borderTopLeftRadius: 5,
                    borderBottomLeftRadius: 5,
                    paddingLeft: 5
                }}
                       defaultValue={valueSearch}
                       placeholder={Config.lang("DHR_Noi_dung_can_tim")}
                       onChange={vl => {
                           this.vlFilter = vl.target.value
                       }}
                       onKeyUp={e => this.onChange(e)}/>

                <span style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: 50,
                    fontSize: 14,
                    fontWeight: 400,
                    lineHeight: 1,
                    color: "#2a76bb",
                    cursor: 'pointer',
                    backgroundColor: "#eeeded",
                    borderRadius: "0 5px 5px 0",
                    textAlign: "center"
                }}
                      onClick={() => this.onSearch()}>
                        <i className="fa fa-search"/>
                </span>
            </div>
        );
    }
}

SearchToolBar.propTypes = {
    onSearch: PropTypes.func,
    onReset: PropTypes.func,
    className: PropTypes.string,
};

export default SearchToolBar;
