export const localize = {
    vi: {
        "Dang_nhap_lai2": "Bạn đã đăng nhập tài khoản trên một thiết bị khác. Vui lòng đăng nhập lại.",
        "ko_du_quyen": "Bạn không đủ quyền, Vui lòng đăng nhập lại",
        "Co_loi_xay_ra_trong_qua_trinh_xu_ly": "Có lỗi xảy ra trong quá trình xử lý."
    },
    en: {
        "Dang_nhap_lai2": "You are already logged in on a different device. Please login again.",
        "ko_du_quyen": "Insufficient permissions. Please log in again.",
        "Co_loi_xay_ra_trong_qua_trinh_xu_ly": "An error occurred during processing."
    },
};
